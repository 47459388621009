import Banner from "../components/home-page/Banner";
import HeroSlider from "../components/home-page/HeroSlider";
import OffersList from "../components/home-page/OffersList";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import UseClickOutside from "../hooks/UseClickOutside";
import Modal from "../components/Modal";

const Home = ({ location, setSnackbar }) => {
    const [offers, setOffers] = useState([]);
    const [offersCount, setOffersCount] = useState();
    const [page, setPage] = useState(1);
    const auth = useSelector((state) => state?.auth?.user);
    const [fetchAgain, setFetchAgain] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [clickedOfferData, setClickedOfferData] = useState();

    const modalRef = useRef(null);

    UseClickOutside(modalRef, () => setShowModal(false));

    const handleOpenModal = (offerData) => {
        setClickedOfferData(offerData);

        setTimeout(() => {
            setShowModal(true)
        }, 100)
    }

    const fetchOffers = async () => {
        setLoading(true);
        try {
            let response;

            if (location?.lat && location?.long) {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/api/offers`, {
                    params: {
                        lat: location.lat,
                        lon: location.long,
                        page,
                        per_page: 10
                    },
                    headers: {
                        Authorization: `Bearer ${auth?.token}`
                    }
                });
            } else {
                response = await axios.get(`${process.env.REACT_APP_API_URL}/api/offers`, {
                    params: {
                        page,
                        per_page: 10
                    },
                    headers: {
                        Authorization: `Bearer ${auth?.token}`
                    }
                });
            }

            setOffersCount(response?.data?.total)
            setOffers(response?.data?.data);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log(e);
        }
    };

    const handleNextPage = () => {
        setPage(prevPage => prevPage + 1);
    };

    const handlePrevPage = () => {
        if (page > 1) {
            setPage(prevPage => prevPage - 1);
        }
    };

    useEffect(() => {
        fetchOffers();
    }, [location, page]);

    useEffect(() => {
        if (fetchAgain) {
            fetchOffers();

            setFetchAgain(false)
        }
    }, [fetchAgain])


    console.log(offers)
    return (
        <>
            {showModal && <Modal forwardRef={modalRef} closeModal={() => setShowModal(false)} offerData={clickedOfferData} />}
            <HeroSlider handleOpenModal={handleOpenModal} />
            <OffersList handleOpenModal={handleOpenModal} loading={loading} setLoading={setLoading} setFetchAgain={setFetchAgain} setSnackbar={setSnackbar} offers={offers} offersCount={offersCount} />
            {!auth?.token && <Banner />}
        </>
    );
};

export default Home;