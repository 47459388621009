import styled from "styled-components";

const Container = styled.div`
    position: absolute;
    bottom: 4%;
    right: 1%;
    z-index: 999;
    padding: 1rem;
    border-radius: 24px 24px 24px 24px;
    width: 540px;
    background-color: white;
    box-shadow: 3px 5px 8px rgba(0, 0, 0, .15);

    @media (max-width: 768px) {
        width: 100%;
        bottom: 2%;
        right: 0;
    }

    p{
        font-size: .9rem;
        line-height: 1.5rem;
    }
`

const Button = styled.button`
    border: none;
    outline: none;
    border-radius: 6px;
    background-color: #8ac926;
    color: white;
    width: 75%;
    padding: 10px;
    font-weight: 800;
    font-size: 1.1rem;
    text-transform: uppercase;
    cursor: pointer;
    border: 2px solid #D4F4BB;
    background: linear-gradient(to top, #7AC143, #94E656);

    @media (max-width: 768px) {
        width: 200px;
        font-size: .9rem;
    }
`

const FloatingDisclaimer = ({setDisclaimerVisible, setDisclaimerAgree }) => {
    const handleAccept = () => {
        sessionStorage.setItem("disclaimer", "accepted");
        setDisclaimerVisible(false);
        setDisclaimerAgree(true);
    };

    const handleDecline = () => {
        sessionStorage.setItem("disclaimer", "declined");
        setDisclaimerVisible(false);
        setDisclaimerAgree(false);
    };
    return(
        <Container>
            <p>By visiting our website, you acknowledge you have read our Privacy Policy and agree to our Terms And Conditions. This website employs the use of third-party session replay technology to monitor and record your website interaction for compliance purpose. For more information, see our Privacy Policy and the TrustedForm Privacy Policy.</p>

            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", gap: "2rem", marginTop: "1rem"}}>
            <Button onClick={handleAccept}>Accept</Button>
                <p onClick={handleDecline} style={{width: "25%", textAlign: "center", textDecoration: "underline", cursor: "pointer"}}>Decline</p>
            </div>
        </Container>
    )
}

export default FloatingDisclaimer;