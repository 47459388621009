import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/features/authSlice";

// Styled components for the header
const TopBar = styled.div`
    background-color: #00ccff;
    padding:  1.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;

    @media (max-width: 1024px) {
        padding: .75rem;
    }

    svg {
        width: 18px;
        height: 18px;
        fill: white;

        @media (max-width: 1024px) {
            width: 14px;
            height: 14px;
        }
    }

    p {
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.2rem;

        @media (max-width: 1024px) {
            font-size: 0.75rem;
        }
    }
`;

const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 60px;
    border-bottom: 1px solid #eee;

    @media (max-width: 1024px) {
        display: none;
    }
`;

const NavContainerLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    flex: 1;

    img {
        width: 200px;
        height: 80px;
        cursor: pointer;

        @media (max-width: 1350px) {
            width: 140px;
            height: 60px;
        }
    }

    p {
        font-weight: 600;
        color: #6c757d;
        font-size: 1.15rem;
        cursor: pointer;

        @media (max-width: 1350px) {
            font-size: 0.9rem;
        }
    }
`;

// const NavContainerMiddle = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     gap: 0.5rem;
//     flex: 2;

//     svg {
//         width: 28px;
//         height: 28px;
//         fill: orange;

//         @media (max-width: 1350px) {
//             width: 20px;
//             height: 20px;
//         }
//     }

//     p {
//         color: #00b4d8;
//         font-weight: 600;
//         font-size: 1.25rem;
//         cursor: pointer;

//         @media (max-width: 1350px) {
//             font-size: 1rem;
//         }
//     }
// `;

const NavContainerRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;
    flex: 1;

    svg {
        width: 16px;
        height: 16px;
        fill: #00b4d8;

        @media (max-width: 1350px) {
            width: 12px;
            height: 12px;
        }
    }

    p {
        color: #00b4d8;
        font-weight: 600;
        font-size: 1rem;
        cursor: pointer;

        @media (max-width: 1350px) {
            font-size: 0.9rem;
        }
    }

    button {
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 12px;
        border: 2px solid #D4F4BB;
        background: linear-gradient(to top, #7AC143, #94E656);
        color: white;
        width: 120px;
        padding: 12px;
        font-weight: 600;
        font-size: 0.9rem;
        text-transform: uppercase;
        margin-right: 0.5rem;
        text-shadow: gray 1px 0 1px;

        @media (max-width: 1350px) {
            width: 80px;
            font-size: 0.7rem;
        }
    }
`;

const NavContainerMobile = styled.div`
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    @media (max-width: 1024px) {
        display: flex;
    }
`;

const NavContainerMobileLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;

    img {
        width: 140px;
        height: 60px;
    }
`;

const NavContainerMobileRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;

    svg {
        width: 28px;
        height: 28px;
        fill: #6c757d;
        cursor: pointer;
    }
`;

const MobileMenu = styled.div`
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    background: #fff;
    height: 100vh;
    width: 100%;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    padding: 20px;
    transform: ${({ $isOpen }) => ($isOpen ? "translateX(0)" : "translateX(100%)")};
    transition: transform 0.3s ease-in-out;
`;

const MobileMenuHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        width: 140px;
        height: 60px;
        cursor: pointer;
    }

    svg {
        width: 28px;
        height: 28px;
        fill: #6c757d;
        cursor: pointer;
    }
`

const MobileMenuItemsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;

    p {
        font-weight: 600;
        color: #6c757d;
        cursor: pointer;
    }
`

const UserInitials = styled.div`
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: teal;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 1.2rem;
    cursor: pointer;
`

const DropdownMenu = styled.div`
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 10px;
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};
    z-index: 1000;

    p {
        padding: 8px;
        margin: 0;
        font-size: 0.8rem;
        color: #333;
        cursor: pointer;
    }
`;

const Header = ({ setSnackbar, location }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const auth = useSelector((state) => state?.auth?.user);
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen((prev) => !prev);
    };

    const handleNavigate = (path) => {
        if (isMobileMenuOpen) {
            setMobileMenuOpen(false)
        }
        navigate(path);
        window.scrollTo(0, 0)
    }

    const logout = () => {
        if (isMobileMenuOpen) {
            setMobileMenuOpen(false)
        }
        dispatch(logoutUser());
        setSnackbar({
            isOpen: true,
            message: "Logged Out Succesfully",
            type: "success"
        });
    }

    return (
        <>
            <TopBar>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M0 80L0 229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7L48 32C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
                </svg>
                <p>Exclusive offers from your favorite brands</p>
            </TopBar>

            <NavContainer>
                <NavContainerLeft>
                    <img onClick={() => handleNavigate("/")} alt="Website Logo" src="/welcome-home_logo.png" />

                    <p onClick={() => handleNavigate("/")}>Offers</p>
                    <p onClick={() => handleNavigate("/about")}>About</p>
                    <p onClick={() => handleNavigate("/contact")}>Contact</p>
                </NavContainerLeft>

                {/* <NavContainerMiddle>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                        <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                    </svg>
                    {location?.lat && location?.long ? <p>Location Shared!</p> : <p>Share Your Location for More Offers!</p>}
                </NavContainerMiddle> */}
                {/* 
                <NavContainerRight>
                    {auth?.token ? (
                        <div style={{ position: "relative" }}>
                            <UserInitials
                                onMouseEnter={() => setDropdownOpen(true)}
                                onMouseLeave={() => setDropdownOpen(false)}
                            >
                                {auth?.user?.first_name?.slice(0, 1)}{auth?.user?.last_name?.slice(0, 1)}
                                <DropdownMenu isOpen={isDropdownOpen}>
                                    <p onClick={logout}>Logout</p>
                                </DropdownMenu>
                            </UserInitials>
                        </div>
                    ) : (
                        <>
                            <button onClick={() => handleNavigate("/signup")}>Sign Up!</button>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path d="M217.9 105.9L340.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L217.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1L32 320c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM352 416l64 0c17.7 0 32-14.3 32-32l0-256c0-17.7-14.3-32-32-32l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32l64 0c53 0 96 43 96 96l0 256c0 53-43 96-96 96l-64 0c-17.7 0-32-14.3-32-32s14.3-32 32-32z" />
                            </svg>
                            <p onClick={() => handleNavigate("/login")}>Login</p>
                        </>
                    )}
                </NavContainerRight> */}
            </NavContainer>

            <NavContainerMobile>
                <NavContainerMobileLeft>
                    <img alt="Website Logo" src="/welcome-home_logo.png" />
                </NavContainerMobileLeft>

                <NavContainerMobileRight>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        onClick={toggleMobileMenu}
                    >
                        <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" />
                    </svg>
                </NavContainerMobileRight>
            </NavContainerMobile>

            <MobileMenu $isOpen={isMobileMenuOpen}>
                <MobileMenuHeader>
                    <img onClick={() => handleNavigate("/")} alt="Website Logo" src="/welcome-home_logo.png" />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        onClick={toggleMobileMenu}
                    >
                        <path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z" />
                    </svg>
                </MobileMenuHeader>

                <MobileMenuItemsList>
                    <p onClick={() => handleNavigate("/")}>Offers</p>
                    <p onClick={() => handleNavigate("/about")}>About</p>
                    <p onClick={() => handleNavigate("/contact")}>Contact</p>

                    {/* {auth?.token ? (
                        <>
                            <p onClick={logout}>Logout</p>
                        </>
                    ) : (
                        <>
                            <p onClick={() => handleNavigate("/signup")}>Sign Up</p>
                            <p onClick={() => handleNavigate("/login")}>Login</p>
                        </>
                    )} */}
                </MobileMenuItemsList>
            </MobileMenu>
        </>
    );
};

export default Header;